<template>
  <v-dialog v-model="dialog" persistent max-width="290">
    <v-card>
      <v-card-title class="headline">กรอกชื่อ</v-card-title>
      <v-card-text>
        <template>
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-text-field
              label="Name"
              v-model="nameVal"
              maxlength="50"
              :rules="nameRules"
              :counter="50"
              required
            ></v-text-field>
          </v-form>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false">ยกเลิก</v-btn>
        <v-btn color="primary" text @click="save">บันทึก</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        this.$emit('on-save', this.name)
        this.dialog = false
      }
    },
    open() {
      this.dialog = true
    },
  },
  computed: {
    nameVal: {
      get() {
        return this.name
      },
      set(v) {
        this.$emit('update:name', v)
      },
    },
  },
  data() {
    return {
      dialog: false,
      valid: true,
      nameRules: [v => !!v || 'จำเป็นต้องกรอกข้อมูลนี้'],
    }
  },
  props: {
    name: {},
  },
}
</script>

<style></style>
