<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="9">
        <v-card :loading="loading">
          <v-toolbar color="indigo darken-3" dark dense flat>
            <v-toolbar-title>
              <v-icon>
                mdi-account-group
              </v-icon>
              รายชื่อเจ้าหน้าที่
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-select
              :items="types"
              label="ค้นหาจาก"
              style="width:40px; !important"
              v-model="type"
              hide-details
              single-line
              dense
              solo-inverted
            ></v-select>
            <v-text-field
              prepend-inner-icon="mdi-magnify"
              width="200px"
              single-line
              hide-details
              dense
              solo-inverted
              label="กรอกคำค้นหา"
              clearable
              v-model="keyword"
              @keypress.enter="getList"
            ></v-text-field>
          </v-toolbar>
          <v-card-text>
            <v-spacer></v-spacer>
            <v-btn color="indigo darken-2" dark class="mr-1" @click="newOfficer">
              <v-icon small>
                mdi-plus-box
              </v-icon>
              เพิ่มเจ้าหน้าที่
            </v-btn>
            <v-btn color="orange darken-4" dark class="mr-1" @click="polisUpdate">
              <v-icon small>
                mdi-file-account
              </v-icon>
              UPDATE จากระบบ POLIS
            </v-btn>
            <v-btn
              color="primary"
              class="white--text mr-1"
              @click="showGroupDialog"
              :disabled="itemSelected.length == 0"
            >
              <v-icon>
                mdi-account-group
              </v-icon>
              {{ edit ? 'แก้ไข' : 'เพิ่มเป็นชุด' }}
            </v-btn>
            <v-btn color="orange" class="white--text" @click="cancel" v-if="itemSelected.length != 0">
              <v-icon>
                mdi-cancel
              </v-icon>
              ยกเลิก
            </v-btn>
          </v-card-text>
          <officer-table
            :keyword="keyword"
            :type="type"
            ref="otable"
            :item-selected.sync="itemSelected"
            @row-dbl-click="editOfficer"
            @remove-officer="deleteOfficer"
          ></officer-table>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card>
          <v-card-title>ชุดเจ้าหน้าที่</v-card-title>
          <v-list>
            <v-list-item v-for="(grp, i) in groups" :key="`list-${i}`">
              <v-list-item-content>{{ grp.name }}</v-list-item-content>
              <v-list-item-action>
                <v-toolbar-items>
                  <v-icon x-small @click="showGroup(grp.id)">mdi-pen</v-icon>
                  <v-icon x-small color="red" @click="deleteGroup(grp.id)">mdi-delete</v-icon>
                </v-toolbar-items>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <!-- dialog -->
    <officer-dialog ref="odlg" v-model="officerDialog" :type="modify" @inserted="onInserted"></officer-dialog>
    <bjp-confirm-dialog ref="confirm"></bjp-confirm-dialog>
    <group-dialog ref="group" @on-save="saveGroup" :name.sync="groupName"></group-dialog>
  </v-container>
</template>

<script>
import officerTable from './components/officerTable'
import officerDialog from './components/officerDialog'
import { destroy, polis } from '@/api/officer'
import groupDialog from './components/groupDialog'
import { list, store, update, destroy as destroyGroup, show } from '@/api/officergroup'
export default {
  methods: {
    getList() {
      this.$refs.otable.getList()
    },
    newOfficer() {
      this.$refs.odlg.init(null, 'add')
      this.officerDialog = true
    },
    editOfficer(id) {
      this.$refs.odlg.init(id, 'edit')
      this.officerDialog = true
    },
    polisUpdate() {
      this.loading = true
      polis()
        .then(res => {
          if (res.success) {
            this.$toast.success({
              message: 'Update ข้อมูลแล้ว',
              title: 'Info.',
            })
            this.$refs.otable.getList()
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    async deleteOfficer(id) {
      let dlg = await this.$refs.confirm.open('ลบรายการ', 'คุณต้องการลบรายการนี้ ?')
      if (dlg) {
        this.loading = true
        destroy(id)
          .then(res => {
            // console.log(res)
            if (res.success) {
              this.$toast.success({
                title: 'info',
                message: 'ทำรายการเรียบร้อยแล้ว',
              })
              this.$refs.otable.getList()
            }
          })
          .catch(err => {
            this.$toast.error({
              message: 'ไม่สามารถทำรายการได้',
              title: 'Error',
            })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    onInserted() {
      this.officerDialog = false
      this.$refs.otable.getList()
    },
    saveGroup(name) {
      const data = { name: name, officers: this.itemSelected.map(o => o.id) }
      if (this.edit) {
        update(this.groupId, data)
          .then(res => {
            if (res.success) {
              this.getGroups()
              this.edit = false
              this.itemSelected = []
              this.$refs.confirm.open('Information', 'แก้ไขเรียบร้อยแล้ว', { noconfirm: true })
            }
          })
          .catch(err => {
            console.log(err)
          })
        return
      }
      store(data)
        .then(res => {
          this.getGroups()
          this.edit = false
          this.itemSelected = []
          this.$refs.confirm.open('Information', 'บันทึกเรียบร้อยแล้ว', { noconfirm: true })
        })
        .catch(err => {
          console.log(err)
        })
    },
    async getGroups() {
      this.groups = await list()
    },
    showGroupDialog() {
      this.$refs.group.open()
    },
    async showGroup(id) {
      this.edit = true
      const res = await show(id)
      this.itemSelected = res.officers
      this.groupId = res.id
      this.groupName = res.name
    },
    cancel() {
      this.edit = false
      this.itemSelected = []
    },
    deleteGroup(id) {
      destroyGroup(id).then(res => {
        if (res.success) {
          this.$refs.confirm.open('Information', 'ลบเรียบร้อยแล้ว', { noconfirm: true })
          this.getGroups()
        }
      })
    },
  },
  data() {
    return {
      keyword: '',
      officerDialog: false,
      type: 'name',
      types: [
        { text: 'ชื่อ-นามสกุล', value: 'name' },
        { text: 'เลข 13 หลัก', value: 'cid' },
        { text: 'ตำแหน่ง', value: 'position' },
      ],
      modify: 'add',
      loading: false,
      itemSelected: [],
      groups: [],
      edit: false,
      groupId: '',
      groupName: 'ชุดที่ #',
    }
  },
  components: {
    officerTable,
    officerDialog,
    groupDialog,
  },
  watch: {},
  created() {
    this.getGroups()
  },
}
</script>

<style></style>
