<template>
  <v-dialog v-model="dialog" persistent max-width="700">
    <v-card :loading="loading">
      <v-card-title class="headline">
        <v-icon>
          mdi-account
        </v-icon>
        ข้อมูลเจ้าหน้าที่
        <span class="subtitle-2"> {{ type == 'add' ? '' : '(แก้ไข)' }}</span>
      </v-card-title>
      <v-container fluid>
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-row> </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-select label="ยศ" v-model="officer.title_id" :items="titles" :rules="reqRule" required></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field label="ชื่อ" v-model="officer.first_name" :rules="reqRule" required></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field label="นามสกุล" v-model="officer.last_name" :rules="reqRule" required></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-select label="ระดับ" v-model="officer.level_id" :items="levels" :rules="reqRule" required></v-select
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field label="ตำแหน่ง" v-model="officer.position" :rules="reqRule" required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                label="เลข 13 หลัก"
                v-model="officer.cid"
                :rules="cidRule"
                maxlength="13"
                :counter="13"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="เบอร์โทร"
                v-model="officer.phone"
                :rules="phoneRule"
                maxlength="10"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field label="เลขประจำตัว" v-model="officer.police_number"></v-text-field>
            </v-col>
          </v-row>

          <v-row> </v-row>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click.native="cancel">ยกเลิก</v-btn>
        <v-btn color="blue darken-3" @click.native="submit" dark>
          <v-icon>
            mdi-content-save
          </v-icon>
          บันทึกข้อมูล
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { level, title, show, store, update } from '@/api/officer'
export default {
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true
        if (this.type == 'add') {
          store(this.officer)
            .then(res => {
              this.$toast.success({
                title: 'Success',
                message: 'เพิ่มข้อมูลเรียบร้อยแล้ว',
              })
              this.$emit('inserted')
            })
            .catch(err => {
              this.$toast.error({
                title: 'Error',
                message: 'เกิดข้อผิดพลาดระหว่างทำรายการ',
              })
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          update(this.officer)
            .then(res => {
              if (res.success) {
                this.$toast.success({
                  title: 'Success',
                  message: 'เพิ่มข้อมูลเรียบร้อยแล้ว',
                })
                this.$emit('inserted')
              }
            })
            .catch(err => {
              this.$toast.error({
                title: 'Error',
                message: 'เกิดข้อผิดพลาดระหว่างทำรายการ',
              })
            })
            .finally(() => {
              this.loading = false
            })
        }
      }
    },
    clear() {
      this.$refs.form.reset()
    },
    async init(id, type) {
      if (type == 'add') {
        this.type = 'add'
        this.officer = Object.assign({}, this.officer_init)
      } else {
        this.type = 'edit'
        this.officer = Object.assign({}, await show(id))
      }
    },
    cancel() {
      this.officer = Object.assign({}, this.officer_init)
      this.$refs.form.resetValidation()
      this.dialog = false
    },
    // utils
    async getTitle() {
      this.titles = await title()
    },
    async getLevel() {
      this.levels = await level()
    },
  },
  data: () => ({
    valid: true,
    loading: false,
    levels: [],
    titles: [],
    type: 'add',
    officer_init: {
      id: null,
      title_id: null,
      first_name: '',
      last_name: '',
      position: '',
      level_id: null,
      cid: '',
      phone: '',
      police_number: '',
    },
    officer: {},
    reqRule: [v => !!v || 'จำเป็นต้องกรอกข้อมูล'],
    cidRule: [v => !!v || 'จำเป็นต้องกรอกข้อมูล', v => /\d{13}/.test(v) || 'กรุณากรอกเป็นตัวเลขเท่านั้น'],
    phoneRule: [v => !!v || 'จำเป็นต้องกรอกข้อมูล', v => /\d{9,10}/.test(v) || 'กรุณากรอกเป็นตัวเลขเท่านั้น'],
  }),
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
  created() {
    this.getTitle()
    this.getLevel()
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style></style>
