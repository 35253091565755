<template>
  <v-data-table
    v-model="itemSelectedVal"
    :headers="headers"
    :loading="loading"
    :items="lists"
    :options.sync="options"
    :items-per-page.sync="tableMeta.per_page"
    :server-items-length="tableMeta.total"
    :footer-props="footer"
    show-select
    class="elevation-1 row-pointer"
    @dblclick:row="rowClick"
  >
    <template v-slot:[`item.plan_date`]="{ item }">
      {{
        moment(item.plan_date)
          .add(543, 'year')
          .format('D MMMYYYY')
      }}
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-speed-dial direction="left" open-on-hover>
        <v-btn slot="activator" fab icon dark small color="indigo">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              x-small
              fab
              @click.stop="updateFromPolis(item.id)"
              color="blue darken-2"
              dark
              v-on="on"
              v-bind="attrs"
              v-if="item.can.update"
            >
              <v-icon small>mdi-download</v-icon>
            </v-btn>
          </template>
          <span>Update ข้อมูลเจ้าหน้าที่จากระบบ Polis</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              x-small
              color="primary"
              v-on="on"
              v-bind="attrs"
              @click="rowClick($event, { item: { id: item.id } })"
              v-if="item.can.update"
            >
              <v-icon>mdi-pen</v-icon>
            </v-btn>
          </template>
          <span>แก้ไข</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="item.can.delete"
              x-small
              fab
              @click.stop="removeData(item.id)"
              color="red"
              dark
              v-on="on"
              v-bind="attrs"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>ลบข้อมูล</span>
        </v-tooltip>
      </v-speed-dial>
    </template>
  </v-data-table>
</template>

<script>
import { list, polisInfo } from '@/api/officer'
import { mapGetters } from 'vuex'
export default {
  methods: {
    getList() {
      this.loading = true
      const { page, itemsPerPage } = this.options
      const param = {
        page: page,
        limit: itemsPerPage,
        keyword: this.keyword,
        type: this.type,
        start: this.dateStart,
        end: this.dateEnd,
        bureau: this.bureauId,
        division: this.divisionId,
        station: this.stationId,
      }
      list(param)
        .then(res => {
          this.lists = res.data
          let meta = res.meta
          let itemsPerPage = parseInt(meta.per_page)
          this.tableMeta = {
            itemsPerPage: itemsPerPage,
            per_page: itemsPerPage,
            page: meta.current_page,
            total: meta.total,
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    rowClick(e, data) {
      if (!data.item.can.update) return
      this.$emit('row-dbl-click', data.item.id)
    },
    removeData(id) {
      this.$emit('remove-officer', id)
    },
    updateFromPolis(id) {
      polisInfo(id)
        .then(res => {
          if (res.success) {
            this.getList()
            this.$toast.success({
              title: 'Info',
              message: 'ทำรายการเรียบร้อยแล้ว',
            })
          } else {
            this.$toast.error({
              title: 'Error',
              message: 'ทำรายการไม่สำเร็จ',
            })
          }
        })
        .catch(err => {
          this.$toast.error({
            title: 'Error',
            message: 'ทำรายการไม่สำเร็จ',
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  props: {
    keyword: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    itemSelected: {
      type: Array,
      default() {
        return []
      },
    },
  },
  watch: {
    options: {
      handler() {
        this.loading = true
        this.getList()
      },
      deep: true,
    },
    bureauId(v) {
      this.getList()
    },
    divisionId(v) {
      this.getList()
    },
    stationId(v) {
      this.getList()
    },
    datesSelected(v) {
      this.getList()
    },
  },
  created() {
    this.getList()
  },
  data() {
    return {
      loading: true,
      headers: [
        {
          text: 'ชื่อ-นามสกุล',
          sortable: false,
          value: 'full_name',
        },
        {
          text: 'ตำแหน่ง',
          sortable: false,
          value: 'position',
        },
        { text: 'สถานี', value: 'station.name_abbr', sortable: false },
        {
          text: 'บก./ภ.จว.',
          value: 'station.division.name_abbr',
          sortable: false,
        },
        {
          text: 'เบอร์โทร.',
          value: 'phone',
          sortable: false,
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      footer: {
        itemsPerPageOptions: [10, 50, 100, 200],
        showCurrentPage: true,
        showFirstLastPage: true,
      },
      tableMeta: {
        itemsPerPage: 10,
        per_page: 10,
        page: 1,
        total: 0,
      },
      lists: [],
      options: {},
    }
  },
  computed: {
    itemSelectedVal: {
      get() {
        return this.itemSelected
      },
      set(v) {
        this.$emit('update:itemSelected', v)
      },
    },
    ...mapGetters('Appfilter', [
      'bureaus',
      'bureauId',
      'divisionId',
      'stationId',
      'divisions',
      'stations',
      'dateStart',
      'dateEnd',
      'datesSelected',
    ]),
  },
}
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
